// Not Found 404 Page

import React from 'react';
import '../css/NotFound.css';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import remove from '../assets/images/remove.png';
import volcano from '../assets/images/volcano.png';

const NotFound = () => {
    React.useEffect(() => {
        document.title = "MosMaiMe - Not Found";
    }, []);

    const [countDownRedirect, setCountDownRedirect] = React.useState(15);

    const goToHomePage = () => {
        document.getElementById("not-found-page")!.style.transition = "opacity 1.5s";
        document.getElementById("not-found-page")!.style.opacity = "0";

        setTimeout(() => {
            window.location.href = "/";
        }, 1500);
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountDownRedirect(countDownRedirect - 1);
        }, 1000);

        if (countDownRedirect === 0) {
            clearInterval(interval);
            goToHomePage();
        }

        return () => clearInterval(interval);
    }, [countDownRedirect]);

    const theme = createTheme({
        palette: {
            primary: {
                main: "#ff1744"
            }
        },
    });
    return (
        <div
            className="not-found-container"
            id="not-found-page"
        >

            <motion.img
                src={volcano}
                alt="remove"
                className="not-found-image"
                animate={{
                    x: [0, 10, -10, 10, -10, 10, -10, 10, -10, 0],
                }}
                transition={{
                    duration: 0.5,
                    repeat: 1,
                    repeatType: "reverse",
                    ease: "easeInOut",
                }}
            />

            <h1 className="not-found-title">404 Page Not Found</h1>

            <motion.div
                className="not-found-button-container"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={goToHomePage}
                    >
                        Go Home
                    </Button>
                </ThemeProvider>
            </motion.div>
            <h2 className="not-found-countdown">
                Redirecting to home page in {countDownRedirect} seconds
            </h2>

        </div>
    );
}

export default NotFound;