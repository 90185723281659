// Profile page
import React from 'react';

const Profile = () => {
    React.useEffect(() => {
        document.title = "MosMaiMe - Profile";
    }, []);

    return (
        <div>
            <h1>Profile</h1>
        </div>
    );
}

export default Profile;