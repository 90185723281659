import React from 'react';
import { createBrowserRouter } from "react-router-dom";

import Home from './pages/Home';
import About from './pages/About';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "about",
        element: <About />,
    },
    {
        path: "profile",
        element: <Profile />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
]);

export default router;