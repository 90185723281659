import React from 'react';
import { motion, useScroll } from 'framer-motion';
import '../css/Home.css';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Icon } from '@mui/material';
import cloud from '../assets/images/cloud.png';
import sun from '../assets/images/sun.png';
import tree from '../assets/images/tree.png';

const Home = () => {
    const titleContainerRef = React.useRef<HTMLDivElement>(null);
    const secondContainerRef = React.useRef<HTMLDivElement>(null);
    const footerContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        document.title = "MosMaiMe - Home";
    }, []);

    const { scrollYProgress } = useScroll();
    const [yPos, setYPos] = React.useState(0);

    React.useEffect(() => {
        scrollYProgress.onChange(setYPos);
    }, [scrollYProgress]);

    const goToProfilePage = () => {
        document.getElementById("home-page")!.style.transition = "opacity 1.5s";
        document.getElementById("home-page")!.style.opacity = "0";

        setTimeout(() => {
            window.location.href = "/profile";
        }, 1500);
    };

    const classNameContainers = [
        "title-container",
        "second-container",
        "footer-container"
    ];

    const currentContainer = window.location.hash.substring(1) || classNameContainers[0];
    const [currentContainerIndex, setCurrentContainerIndex] = React.useState(classNameContainers.indexOf(currentContainer));
    const [disablePrev, setDisablePrev] = React.useState(true);
    const [disableNext, setDisableNext] = React.useState(false);
    const [primaryColor, setPrimaryColor] = React.useState("#2196f3");

    const goToPreviousContainer = () => {
        if (currentContainerIndex > 0) {
            setCurrentContainerIndex(currentContainerIndex - 1);
        }
    };

    const goToNextContainer = () => {
        if (currentContainerIndex < classNameContainers.length - 1) {
            setCurrentContainerIndex(currentContainerIndex + 1);
        }
    };

    const updateHashWhenScroll = () => {
        if (window.scrollY < secondContainerRef.current!.offsetTop) {
            window.location.hash = classNameContainers[0];
            setPrimaryColor("#2196f3");
            // setCurrentContainerIndex(0);
        } else if (window.scrollY < footerContainerRef.current!.offsetTop) {
            window.location.hash = classNameContainers[1];
            setPrimaryColor("#009688");
            // setCurrentContainerIndex(1);
        } else if (window.scrollY >= footerContainerRef.current!.offsetTop) {
            window.location.hash = classNameContainers[2];
            setPrimaryColor("#673ab7");
            // setCurrentContainerIndex(2);
        }
    };


    window.addEventListener("load", () => {
        window.addEventListener("scroll", updateHashWhenScroll);
    });

    React.useEffect(() => {

        switch (currentContainerIndex) {
            case 0:
                titleContainerRef.current!.scrollIntoView({ behavior: "smooth" });
                break;
            case 1:
                secondContainerRef.current!.scrollIntoView({ behavior: "smooth" });
                break;
            case 2:
                footerContainerRef.current!.scrollIntoView({ behavior: "smooth" });
                break;
            default:
                break;
        }
    }, [classNameContainers.length, currentContainerIndex]);




    React.useEffect(() => {
        setDisablePrev(currentContainerIndex === 0);
        setDisableNext(currentContainerIndex === classNameContainers.length - 1);
    }, [currentContainerIndex, classNameContainers.length]);


    const theme = createTheme({
        palette: {
            primary: {
                main: "#009688"
            }
        },
    });

    return (
        <motion.div className="container"
            id="home-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
        >
            <div className="arrow-up-down-container">
                <motion.div
                    className='arrow-button'
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={goToPreviousContainer}
                    style={{ opacity: disablePrev ? 0 : 1, backgroundColor: disablePrev ? "transparent" : primaryColor }}
                >
                    <Icon className='arrow-down'>
                        <KeyboardArrowUpIcon />
                    </Icon>
                </motion.div>

                <motion.div
                    className='arrow-button'
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={goToNextContainer}
                    style={{ opacity: disableNext ? 0 : 1, backgroundColor: disableNext ? "transparent" : primaryColor }}
                >
                    <Icon className='arrow-up'>
                        <KeyboardArrowDownIcon />
                    </Icon>
                </motion.div>

            </div>
            <div className="title-container" ref={titleContainerRef}>
                <motion.span
                    className="cloud-container cloud-container-left"
                    initial={{ x: -1000 }}
                    animate={{ x: -600 }}
                    transition={{ duration: 2 }}
                >
                    <img
                        src={cloud}
                        alt="cloud"
                        className="cloud"
                        style={{ transform: "scale(1.3) scaleX(-1)" }}

                    />
                </motion.span>
                <motion.div
                    className="cloud-container cloud-container-right"
                    initial={{ x: 1000 }}
                    animate={{ x: 600 }}
                    transition={{ duration: 2 }}
                >
                    <img
                        src={cloud}
                        alt="cloud"
                        className="cloud"
                    />
                </motion.div>
                <motion.div
                    className="sun-container"
                    initial={{ y: 1000 }}
                    animate={{ y: -50 }}
                    transition={{ duration: 3 }}
                >
                    <img
                        src={sun}
                        alt="sun"
                        className="sun"
                    />
                </motion.div>
                <motion.h1
                    className="title"
                    style={{
                        scale: 1 + yPos * 3,
                    }}
                >
                    Welcome to MosMaiMe!
                </motion.h1>
            </div>
            <div className="second-container" ref={secondContainerRef}>
                <motion.div
                    className="tree-container tree-container-left"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 1 }}
                >
                    <img
                        src={tree}
                        alt="tree"
                        className="tree"
                    />
                </motion.div>
                <motion.h2
                    className="second-title"
                    style={{
                        scale: yPos < 0.5 ? 1 + yPos * 2 : 1 + (1 - yPos) * 2,
                    }}
                >
                    My name is Mos.
                </motion.h2>
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={goToProfilePage}
                        >
                            Go to Profile
                        </Button>
                    </ThemeProvider>
                </motion.div>

            </div>
            <div className="footer-container" ref={footerContainerRef}>
                <div className="footer">
                    <h1 className="footer-title">
                        © 2022 MosMaiMe - Created by Dulyawat P.
                    </h1>

                    <div className="footer-credits">
                        <h2 className="footer-credit">
                            Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                        </h2>

                    </div>
                </div>
            </div>
        </motion.div >
    );
}

export default Home;